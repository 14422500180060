import { useEffect, useState } from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Box from '@mui/material/Box'
import ControlledTextField from '../../components/forms/controlledTextField'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import InputAdornment from "@mui/material/InputAdornment"
import useAppStore from '../../store/app'
import { useLazyPost } from '../../utils/hooks'
import { useNavigate } from "react-router-dom"
import useAuthStore from "../../store/auth"

import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'


const LoginPage = () => {
  const [isNewPassword, setIsNewPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { control, handleSubmit } = useForm()
  const navigate = useNavigate()
  const [tryLogin, { data, error, isLoading }] = useLazyPost('users/auth/')
  const { toggleDarkMode, isDarkMode } = useAppStore((state) => state)
  const saveUserData = useAuthStore(state => state.saveUserData)
  const toggleShowPassword = () => {
    setShowPassword((oldValue) => !oldValue)
  }

  const loginHandler = async (formData) => {
    if (isNewPassword) return this.updatePassword()
    const data = {
      email: formData.email,
      password: formData.password
    }
    await tryLogin(data)
  }

  useEffect(() => {
    if(error) {
      console.log(error)
      return
    }
    if(isLoading) return
    if (!data) return

    saveUserData(data)
    if (data.action === 'NPR') {
      setIsNewPassword(true)
    } else {
      navigate('/dashboard')
    }
  }, [data, error, isLoading, navigate, saveUserData])
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '400px', marginTop: '200px', marginBottom: '32px' }}>
        <Paper sx={{ padding: '16px' }}>
          <form onSubmit={handleSubmit(loginHandler)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <Typography variant="h4">
                Login
              </Typography>
              <ControlledTextField
                control={control}
                id='email'
                name='email'
                label='E-mail'
                rules={{ required: { value: true, message: 'Campo é obrigatório' } }}
              />
              {isNewPassword && (
                <>
                  <ControlledTextField
                    control={control}
                    id='newPassword'
                    type={showPassword ? 'text' : 'password'}
                    name='newPassword'
                    label='Nova Senha'
                    rules={{ required: { value: true, message: 'Campo é obrigatório' } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleShowPassword}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ControlledTextField
                    control={control}
                    id='passwordConfirmation'
                    name='passwordConfirmation'
                    type={showPassword ? 'text' : 'password'}
                    label='Confirmação Senha'
                    rules={{ required: { value: true, message: 'Campo é obrigatório' } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleShowPassword}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /></>
              )}
              <ControlledTextField
                control={control}
                id='password'
                name='password'
                type={showPassword ? 'text' : 'password'}
                label='Senha'
                rules={{ required: { value: true, message: 'Campo é obrigatório' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button variant="contained" sx={{ alignSelf: 'flex-end' }} type="submit">Acessar</Button>
            </Box>
          </form>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
          <IconButton onClick={toggleDarkMode}>
            {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
export default LoginPage