import { useMemo } from 'react'
import { Box } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'

const CustomCard = (props) => {
  const { icon, iconBackground = '', label = '', value = '', loading = true } = props
  
  const renderValue = useMemo(() => {
    if(loading || !value) return (
      <Skeleton variant="rectangular" width={140} height={45} />
    )
    return <Typography variant="h4" component="div">{value}</Typography>
  }, [value, loading])

  return (
    <Card sx={{flex: 1, minWidth: '275px' }} elevation={2}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Paper elevation={1} sx={{ display: 'flex', padding: '8px', justifyContent: 'center', alignItems: 'center', backgroundColor: iconBackground }} >
              {icon}
            </Paper>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
            { renderValue}
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              {label}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default CustomCard