import { useState, useMemo, useCallback, useEffect } from 'react'
import Box from '@mui/material/Box'
import Paper from "@mui/material/Paper"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import CustomCard from '../../components/base/card'
import CustomTable from '../../components/base/table'
import { addDays, format, isSameDay, subMonths } from 'date-fns'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useLazyPost } from '../../utils/hooks'
import useAuthStore from "../../store/auth"
import { ICON_PROPS, MONTHS} from '../../utils/consts'
import { formatTimeToStr } from '../../utils/helpers'

const NUM_BATIDAS = 7

const COLUMNS = [
  { field: 'date', headerName: 'Dia', width: 100, valueGetter: (val, row) => format(new Date(row.date), 'dd/MM/yyyy') },
  { field: 'hour', headerName: 'Hora', width: 80, valueGetter: (val, row) => format(new Date(row.date), 'HH:mm') },
  {
    field: 'type',
    headerName: 'Tipo',
    width: 90,
    sortable: false,
    valueGetter: (value, row) => row.isClosing ? 'Saída' : 'Entrada',
  },
  {
    field: 'description',
    headerName: 'Descrição',
    sortable: false,
    width: 230,
  },
  {
    field: 'isCorrected',
    headerName: 'Corrigido',
    sortable: false,
    width: 160,
    type: 'boolean',
  }
]

const MonthlyReportPage = () => {
  const user = useAuthStore(state => state.user)
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [year, setYear] = useState(new Date().getFullYear())
  const [loadAppointments, { data, isLoading, error }] = useLazyPost('appointment/getByDate')

  const baseDate = useMemo(() => {
    let baseDate = new Date()
    baseDate.setFullYear(year)
    baseDate.setMonth(month - 1)
    return baseDate
  }, [month, year])

  const loadData = useCallback(async () => {
    if(isLoading) return
    const startDate = subMonths(baseDate, 1).setDate(21)
    const endDate = new Date(baseDate).setDate(20)
    const params = {
      discordUser: user.discordUser,
      date: [startDate, endDate]
    }
    await loadAppointments(params)
  }, [loadAppointments, user, baseDate, isLoading])

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseDate])

  const rows = useMemo(() => {
    if (error || isLoading) return []
    if (!data) return []
    return data.allDayAppointments
  }, [data, isLoading, error])

  const handleChange = (e, param) => {
    if (param === 'month') {
      setMonth(e.target.value)
    } else {
      setYear(e.target.value)
    }
  }

  const renderMonth = useMemo(() => {
    return MONTHS.map(({ label, value }) => {
      return <MenuItem value={value} key={value}>{label}</MenuItem>
    })
  }, [])

  const renderYear = useMemo(() => {
    const currentYear = (new Date()).getFullYear()
    const render = []
    for (let i = 2024; i < (currentYear + 1); i++) {
      render.push(<MenuItem value={i} key={i}>{i}</MenuItem>)
    }
    return render
  }, [])

  const periodHours = useMemo(() => {
    if (error || isLoading) return 0
    if (!data) return 0
    return formatTimeToStr(data.dailyWorkingTime)
  }, [data, isLoading, error])

  const annualLeave = useMemo(() => {
    return formatTimeToStr(user.annualLeave)
  }, [user])

  const savePDF = useCallback(() => {
    window.print()
  }, [])

  const saveCSV = useCallback(() => {
    let csvContent = 'data, '
    for (let i = 0; i < NUM_BATIDAS; i++) {
      csvContent += `entrada${i + 1}, saida${i + 1}, `
    }
    csvContent += '\n'
    const appointments = data.allDayAppointments
    const startDate = subMonths(baseDate, 1).setDate(21)
    const endDate = new Date(baseDate).setDate(21)
    const map = {}
    let d = startDate
    while (!isSameDay(d, endDate)) {
      const key = format(d, 'dd/MM/yyyy')
      map[key] = []
      d = addDays(d, 1)
    }
    for (const apt of appointments) {
      const d = new Date(apt.date)
      const key = format(d, 'dd/MM/yyyy')
      if (!map[key]) map[key] = []
      map[key].push(format(d, 'HH:mm:ss'))
    }
    csvContent += Object.keys(map).map(date => {
      while (map[date].length < 2 * NUM_BATIDAS) map[date].push('')
      return `${date}, ${map[date].join(', ')}`
    }).join('\n')
    const blob = new Blob([csvContent], { type: 'application/csv' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `ponto-${user.name}-${month + 1}-${year}.csv`
    link.click()
    URL.revokeObjectURL(link.href)
  }, [baseDate, data, user, month, year])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }} >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '16px', minWidth: '25%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }} >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '16px', flexGrow: 1 }}>
            <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px' }}>
              <Typography variant='h5'>
                Parâmetros
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', marginBottom: '16px' }}>
                <FormControl fullWidth sx={{ minWidth: '120px' }}>
                  <InputLabel id="month-select-label">Mês</InputLabel>
                  <Select
                    labelId="month-select-label"
                    id="month-select"
                    value={month}
                    label="Mês"
                    onChange={(e) => handleChange(e, 'month')}
                  >
                    {renderMonth}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ minWidth: '120px' }}>
                  <InputLabel id="year-select-label">Ano</InputLabel>
                  <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={year}
                    label="Ano"
                    onChange={(e) => handleChange(e, 'year')}
                  >
                    {renderYear}
                  </Select>
                </FormControl>
              </Box>
              <Stack spacing={2} direction="column">
                <Button variant="contained" onClick={savePDF}>Salvar PDF</Button>
                <Button variant="contained" onClick={saveCSV}>Salvar CSV</Button>
              </Stack>
            </Paper>
            <CustomCard
              label="Banco de horas"
              value={annualLeave}
              icon={<AccessTimeIcon {...ICON_PROPS} />}
              iconBackground="primary.main"
              loading={isLoading}
            />
            <CustomCard
              label="Horas realizadas"
              value={periodHours}
              icon={<AccessTimeIcon {...ICON_PROPS} />}
              iconBackground="success.main"
              loading={isLoading}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 1, marginBottom: '16px' }}>
        <CustomTable columns={COLUMNS} rows={rows} loading={isLoading} label='Apontamentos' initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }} />
      </Box>
    </Box>
  )
}

export default MonthlyReportPage