import { useEffect, useMemo } from 'react'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import Badge from '@mui/material/Badge'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { Paper, Typography } from "@mui/material"
import { useLazyPost } from '../../utils/hooks'
import { USER_CLASS_WORK_TIME } from '../../utils/consts'
import { isBefore, isWeekend, subDays } from 'date-fns'
import useAuthStore from "../../store/auth"

const ServerDay = (props) => {
  const { highlightedDays = {}, day, outsideCurrentMonth, loading, ...other } = props

  const isSelected =
    !outsideCurrentMonth && ((isBefore(day, subDays(new Date(), 1)) && !isWeekend(day)) || (isWeekend(day) && highlightedDays[day.getDate()]))

  let color = 'error'
  if (highlightedDays[day.getDate()]) {
    color = isWeekend(day) ? 'success' : highlightedDays[day.getDate()]
  }

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isSelected && !loading ? ' ' : 0}
      variant="dot"
      color={color}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  )
}

const AppointmentCalendar = (props) => {
  const { onDateChangeHandler } = props
  const user = useAuthStore(state => state.user)
  const [loadMonthWorkDays, { data, isLoading, error }] = useLazyPost('appointment/getMonthWorkDays')

  useEffect(() => {
    const params = {
      discordUser: user.discordUser,
      date: new Date()
    }
    loadMonthWorkDays(params)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const highlightedDays = useMemo(() => {
    if (error || isLoading || !data) return {}
    const days = {}
    data.days.forEach(({ day, time }) => {
      let status = 0
      if (time >= USER_CLASS_WORK_TIME[user.class] + 15 * 60) {
        status = 'success'
      } else if (time >= USER_CLASS_WORK_TIME[user.class]) {
        status = 'info'
      } else {
        status = 'warning'
      }
      days[day] = status
    })
    return days
  }, [data, error, isLoading, user])

  const handleMonthChange = (date) => {
    const params = {
      discordUser: user.discordUser,
      date
    }
    loadMonthWorkDays(params)
  }

  return (
    <Paper sx={{flex: 1}} elevation={2}>
      <Typography variant='h5' sx={{padding: '16px'}}>
        Calendário
      </Typography>
      <DateCalendar
        loading={isLoading}
        onMonthChange={handleMonthChange}
        slots={{
          day: ServerDay,
        }}
        onChange={onDateChangeHandler}
        slotProps={{
          day: {
            highlightedDays,
            loading: isLoading
          },
        }}
      />
    </Paper>
  )
}

export default AppointmentCalendar