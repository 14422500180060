import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

const ControlledTextField = ({ name, label, control, disabled = false, rules = {}, ...props }) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState }) => {
      return (<TextField
        {...field}
        {...props}
        error={fieldState.invalid}
        helperText={fieldState.error?.message}
        label={label}
        required={rules?.required?.value === true}
        fullWidth
        disabled={disabled}
      />)
    }}
  />
)

export default ControlledTextField
