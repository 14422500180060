import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { Controller } from 'react-hook-form'

const ControlledTimePicker = ({ name, label, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <TimePicker
        {...field}
        {...props}
        label={label}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
      />
    )}
  />
)

export default ControlledTimePicker