import { useCallback, useState } from "react"
import Box from "@mui/material/Box"
import { Outlet } from 'react-router-dom'
import Navbar from "../components/base/navbar"
import Drawer from "../components/base/drawer"
import MobileDrawer from "../components/base/mobileDrawer"

const MainLayout = () => {
  const [showMobileNavbar, setShowMobileNavbar] = useState(false)
  const toggleMobileNavbar = useCallback(() => {
    setShowMobileNavbar(v => !v)
  }, [])
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh', width: '100vw' }}>
      <Box sx={{ width: '300px',  height: '100vh',  padding: '16px', position: 'fixed', display: {
        xs: 'none',
        md: 'block'
      }}}>
        <Drawer />
      </Box>
      <Box sx={{ width: '300px',  height: '100vh',  padding: '16px', position: 'fixed', display: {
        xs: 'block',
        md: 'none'
      }}}>
        <MobileDrawer open={showMobileNavbar} closeHandler={toggleMobileNavbar} />
      </Box>
      <Box sx={{paddingLeft: {
        xs: 0,
        md: '300px'
      }, flexGrow: 1, margin: '16px' }}>
        <Navbar drawerHandler={toggleMobileNavbar} />
        <Outlet />
      </Box>
    </Box>
  )
}

export default MainLayout