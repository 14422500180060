import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'


const ResetPasswordDialog = (props) => {
  const { open, handleClose, handleOk, user } = props
  const [password, setNewPassword] = useState('')
  useEffect(() => {
    if (!open) {
      setNewPassword('')
    }
  }, [open])
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Reset de senha</DialogTitle>
      <DialogContent>
        <DialogContentText>{'Digite a nova senha do usuário ' + user.name}</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="pass"
          name="pass"
          label="Senha"
          value={password}
          onChange={(e) => setNewPassword(e.target.value)}
          type="text"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Não</Button>
        <Button onClick={() => handleOk(password)}>Sim</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResetPasswordDialog