import DashboardIcon from '@mui/icons-material/Dashboard'
import TodayIcon from '@mui/icons-material/Today'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AssessmentIcon from '@mui/icons-material/Assessment'
import PersonIcon from '@mui/icons-material/Person'

import Dashboard from "../pages/dashboard"
import Appointment from '../pages/appointment'
import UsersPage from '../pages/users'
import MonthlyReportPage from '../pages/monthlyReport'
import ReportPage from '../pages/report'

export const AUTHENTICATED_ROUTES = Object.freeze([
  {
    path: '/dashboard',
    component: <Dashboard />,
    icon: <DashboardIcon fontSize="small" />,
    label: 'Dashboard',
  },
  {
    path: '/appointment',
    component: <Appointment />,
    icon: <TodayIcon fontSize="small" />,
    label: 'Apontamento de horas'
  },
  {
    path: '/monthlyReport',
    component: <MonthlyReportPage />,
    icon: <CalendarMonthIcon fontSize="small" />,
    label: 'Fechamento mensal'
  },
  {
    path: '/report',
    component: <ReportPage />,
    icon: <AssessmentIcon fontSize="small" />,
    label: 'Relatórios',
    adminOnly: true
  },
  {
    path: '/users',
    component: <UsersPage />,
    icon: <PersonIcon fontSize="small" />,
    label: 'Usuários',
    adminOnly: true
  },
])
