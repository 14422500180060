import { useMemo } from 'react'
import Box from "@mui/material/Box"
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import IconButton from "@mui/material/IconButton"
import useAppStore from '../../../store/app'
import { useLocation, useNavigate } from 'react-router-dom'
import { AUTHENTICATED_ROUTES } from '../../../utils/routes'

// Icons
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import logo from '../../../assets/logo.png'
import useAuthStore from '../../../store/auth'

const Drawer = () => {
  const { toggleDarkMode, isDarkMode } = useAppStore((state) => state)
  const user = useAuthStore(state => state.user)
  const navigate = useNavigate()
  const location = useLocation()

  const renderMenu = useMemo(() => {
    const menu = []
    AUTHENTICATED_ROUTES.forEach(({ path, icon, label, adminOnly = false}, index) => {
      if (adminOnly && !user.isAdmin) return
      menu.push(
        <ListItemButton
          key={index}
          selected={path === location.pathname}
          onClick={() => navigate(path)}
        >
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      )
    })
    return menu
  }, [navigate, location, user])
  return (
    <Paper sx={{ height: '100%' }} elevation={2}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Paper elevation={4} sx={{ margin: '16px', padding: '16px', backgroundColor: 'white' }}>
            <img alt='logo' src={logo} style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
            <Typography variant="h5" color={"#000"} sx={{ textAlign: 'center' }}>
              Intranet
            </Typography>
          </Paper>
          <List component="nav" aria-label="main mailbox folders">
            {renderMenu}
          </List>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
          <IconButton onClick={toggleDarkMode}>
            {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Box>
      </Box>
    </Paper>
  )
}

export default Drawer