import { useMemo, useState, useCallback } from 'react'
import CustomTable from '../../components/base/table'
import ConfirmationDialog from '../../components/confirmationDialog'
import ResetPasswordDialog from '../../components/resetPasswordDialog'
import Box from "@mui/material/Box"
import { useGet, useLazyDelete, useLazyPost } from '../../utils/hooks'
import { formatTimeToStr } from '../../utils/helpers'
import { GridActionsCellItem } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import LockResetIcon from '@mui/icons-material/LockReset'
import NewUserModal from '../../components/newUserModal'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

const COLUMNS = (deleteHandler, editHandler, resetPassHandler) => [
  {
    field: 'name',
    headerName: 'Nome',
    align: 'left',
    width: 180,
    sortable: true
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 320,
    align: 'left',
  },
  {
    field: 'type',
    headerName: 'Tipo',
    align: 'left',
    valueGetter: (val, row) => {
      return row.class === 'worker' ? 'Colaborador' : 'Estagiário'
    }
  },
  {
    field: 'discordUser',
    headerName: 'Discord',
    width: 180,
    align: 'left',
  },
  {
    field: 'annualLeave',
    headerName: 'Banco de Horas',
    width: 140,
    align: 'left',
    valueGetter: val => formatTimeToStr(val)
  },
  {
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Editar"
        onClick={() => editHandler(params.row)}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<LockResetIcon />}
        label="Resetar senha"
        onClick={() => resetPassHandler(params.row)}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Deletar"
        onClick={() => deleteHandler(params.row)}
        showInMenu
      />,
    ],
  },
]


const UsersPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isResetPassDialogOpen, setIsResetPassDialogOpen] = useState(false)
  const [rowValue, setRowValue] = useState({})
  const { data, error, isLoading, refetch } = useGet('users')
  const [deleteUser, { error: deleteError }] = useLazyDelete(`users/${rowValue.id}`)
  const [createUser, { error: createError }] = useLazyPost('users/')
  const [updateUser, { error: updateError }] = useLazyPost('users/update')
  const [resetPassword, { error: resetPasswordError }] = useLazyPost(`users/resetPassword`)

  const rows = useMemo(() => {
    if (error || isLoading) return []
    if (!data) return []
    return data
  }, [error, isLoading, data])

  const closeModal = useCallback(() => {
    setRowValue({})
    setIsModalOpen(false)
  }, [])

  const saveHandler = async (formData) => {
    let error
    const data = {
      name: formData.name,
      userClass: formData.userClass,
      discordUser: formData.discordUser,
      email: formData.email,
      password: formData.password,
      userType: formData.userType,
    }
    if (isEditMode) {
      data.id = rowValue.id
      await updateUser(data)
      if (updateError) error = updateError
    } else {
      await createUser(data)
      if (createError) error = createError
    }
    if (error) {
      console.log(error)
      return
    }
    refetch()
    setRowValue({})
    setIsModalOpen(false)
  }

  const deleteHandler = (data) => {
    setRowValue(data)
    setIsDeleteDialogOpen(true)
  }

  const deleteCancel = () => {
    setRowValue({})
    setIsDeleteDialogOpen(false)
  }

  const deleteOk = async () => {
    await deleteUser()
    if (deleteError) {
      console.log(deleteError)
      return
    }
    setRowValue({})
    setIsDeleteDialogOpen(false)
    refetch()
  }

  const editHandler = (data) => {
    setRowValue({ ...data, userType: data.isAdmin, userClass: data.class })
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  const createHandler = () => {
    setIsEditMode(false)
    setIsModalOpen(true)
  }

  const resetPassHandler = (data) => {
    setRowValue({ ...data, userType: data.isAdmin, userClass: data.class })
    setIsResetPassDialogOpen(true)
  }

  const resetCancel = () => {
    setRowValue({})
    setIsResetPassDialogOpen(false)
  }

  const resetOk = async (newPassword) => {
    const data = {
      newPassword,
      email: rowValue.email
    }
    await resetPassword(data)
    if (resetPasswordError) {
      console.log(error)
      return
    }
    setRowValue({})
    setIsResetPassDialogOpen(false)
  }


  return (
    <Box sx={{ flex: 1 }}>
      <CustomTable
        rows={rows}
        loading={isLoading}
        columns={COLUMNS(deleteHandler, editHandler, resetPassHandler)}
        label='Usuários'
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
      />
      <NewUserModal
        open={isModalOpen}
        handleClose={closeModal}
        isEditMode={isEditMode}
        saveHandler={saveHandler}
        values={rowValue}
      />
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        title='Exclusão'
        message={`Deseja realmente excluir o usuário ${rowValue.name}`}
        handleClose={deleteCancel}
        handleOk={deleteOk}
      />
      <ResetPasswordDialog
        open={isResetPassDialogOpen}
        user={rowValue}
        handleClose={resetCancel}
        handleOk={resetOk}
      />
      <Box sx={{ position: 'fixed', right: 0, bottom: 0, margin: '32px 16px' }}>
        <Fab color="secondary" aria-label="fast check-in">
          <AddIcon onClick={createHandler} />
        </Fab>
      </Box>
    </Box>
  )
}

export default UsersPage