import { ptBR } from '@mui/x-data-grid/locales'
import { ptBR as pickersPtBR } from '@mui/x-date-pickers/locales';
import { ptBR as corePtBR } from '@mui/material/locale'
import { createTheme } from '@mui/material'

const getThemeOptions = (mode = 'light') => {
  return {
    palette: {
      mode,
      primary: {
        main: '#027be3',
      },
      secondary: {
        main: '#26A69A',
      },
      success: {
        main: '#21BA45',
      },
      error: {
        main: '#DB2828',
      },
      info: {
        main: '#31CCEC',
      },
      warning: {
        main: '#F2C037',
      },
    },
    props: {
      MuiAppBar: {
        color: 'secondary',
      },
    },
  }
}

export const getTheme = (mode = 'light') => {
  const options = getThemeOptions(mode)
  return createTheme(
    options,
    ptBR,
    pickersPtBR,
    corePtBR,
  )
}