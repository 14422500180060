import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { API_URL_MAP } from './consts'
const baseURL = API_URL_MAP[process.env.NODE_ENV] || API_URL_MAP['dev']
// const baseURL = API_URL_MAP['production']
export const useGet = (route, options) => {
  const [data, setData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null) 

  const fetchData = useCallback(async () => {
    if(!route) return
    setIsLoading(true)
    try {
      const response = await axios(baseURL + route, { ...options, method: 'get'})
      setData(response.data)
    } catch (e) {
      setData('')
      setError(e)
    }
    finally {
      setIsLoading(false)
    }
  }, [route, options])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const refetch = useCallback(() => {
    fetchData()
  }, [fetchData])

  return { data, isLoading, error, refetch }
}

export const usePost = (route, options) => {
  const [data, setData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null) 
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios(baseURL + route, {...options, method: 'post'})
        setData(response.data)
      } catch (e) {
        setData('')
        setError(e)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { data, isLoading, error }
}

export const useLazyPost = (route, options) => {
  const [data, setData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null) 
  
  const fetchData = useCallback(async (data) => {
    setIsLoading(true)
    try {
      const response = await axios(baseURL + route, {...options, method: 'post', data })
      setData(response.data)
    } catch (e) {
      console.log(e)
      setData('')
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }, [route, options])

  return [fetchData, {data, isLoading, error }]
}

export const useLazyDelete = (route, options) => {
  const [data, setData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null) 
  
  const fetchData = useCallback(async (data) => {
    setIsLoading(true)
    try {
      const response = await axios(baseURL + route, {...options, method: 'delete', data })
      setData(response.data)
    } catch (e) {
      setError(e)
      setData('')
    } finally {
      setIsLoading(false)
    }
  }, [route, options])

  return [fetchData, {data, isLoading, error }]
}
