import { useEffect } from 'react'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box from "@mui/material/Box"
import Stack from '@mui/material/Stack'
import { useForm } from "react-hook-form"
import ControlledTextField from '../forms/controlledTextField'
import ControlledSelect from '../forms/controlledSelect'
import ControlledTimePicker from '../forms/controlledTimePicker'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

const DEFAULT_VALUES = {
  hour: new Date(),
  type: false,
  description: '',
}

const NewAppointmentModal = (props) => {
  const { open, handleClose, saveHandler, isEditMode = false, values = {} } = props
  const { control, handleSubmit, reset } = useForm({ defaultValues: isEditMode ? values : DEFAULT_VALUES })

  useEffect(() => {
    if (!open) return
    reset(isEditMode ? values : { ...DEFAULT_VALUES, hour: new Date() })
  }, [open, reset, isEditMode, values])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ paddingBottom: '32px' }}>
          Novo Apontamento
        </Typography>
        <form onSubmit={handleSubmit(saveHandler)}>
          <Stack spacing={2}>
            <ControlledSelect
              control={control}
              id="type"
              name="type"
              label="Tipo"
              variant="outlined"
              disabled={isEditMode}
              options={[
                <MenuItem value={false} key={1}>Entrada</MenuItem>,
                <MenuItem value={true} key={2}>Saída</MenuItem>
              ]}
            />
            <ControlledTimePicker
              control={control}
              id="hour"
              name='hour'
              label='Hora'
            />
            <ControlledTextField
              control={control}
              id="description"
              name="description"
              label="Descrição"
              variant="outlined"
              multiline
              rows={4}
            />
            <Stack spacing={2} direction='row' sx={{ justifyContent: 'flex-end' }}>
              <Button variant="text" onClick={() => handleClose()}>Cancelar</Button>
              <Button variant="contained" type='submit' >Salvar</Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Modal>
  )
}

export default NewAppointmentModal