import { useMemo } from 'react'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { BrowserRouter } from 'react-router-dom'
import MainLayout from './layouts/mainLayout'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'
import { Routes, Route, Navigate } from 'react-router-dom'
import { getTheme } from './utils/theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import useAppStore from './store/app'

import Login from './pages/login'
import useAuthStore from './store/auth'
import { AUTHENTICATED_ROUTES } from './utils/routes'

function App() {
  const isDarkMode = useAppStore((state) => state.isDarkMode)
  const isAuthenticated = useAuthStore(state => state.user.id)
  const theme = useMemo(() => {
    return getTheme(isDarkMode ? 'dark' : 'light')
  }, [isDarkMode])

  const renderAuthenticatedRoutes = useMemo(() => {
    const routeList = AUTHENTICATED_ROUTES.map((route) => {
      return <Route exact path={route.path} element={route.component} key={route.path} />
    })
    return (
      <Route path='/' element={<MainLayout />} >
        {routeList}
        <Route index element={<Navigate to="/dashboard" replace={true} />} />
      </Route>
    )
  }, [])

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            {!isAuthenticated && <Route path='*' element={<Login />} />}
            {isAuthenticated && renderAuthenticatedRoutes}
            {/* <Route path='*' element={<NoPage />} /> */}
          </Routes>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  )
}

export default App
