import { useState, useMemo, useCallback, useEffect } from 'react'
import Box from '@mui/material/Box'
import Paper from "@mui/material/Paper"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import { set, setDate, subMonths } from 'date-fns'
import { useLazyPost } from '../../utils/hooks'
import { formatTimeToStr } from '../../utils/helpers'
import { MONTHS } from '../../utils/consts'

const ReportPage = () => {
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [year, setYear] = useState(new Date().getFullYear())
  const [loadReport, { data, isLoading, error }] = useLazyPost('report/getReport')

  const baseDate = useMemo(() => {
    const baseDate = new Date()
    return set(baseDate, { year, month: month - 1 })
  }, [month, year])

  const loadData = useCallback(async () => {
    if (isLoading) return
    const startDate = setDate(subMonths(baseDate, 1), 22)
    const endDate = setDate(baseDate, 20)
    const params = {
      init: startDate,
      end: endDate,
    }
    await loadReport(params)
  }, [loadReport, baseDate, isLoading])

  const handleChange = (e, param) => {
    if (param === 'month') {
      setMonth(e.target.value)
    } else {
      setYear(e.target.value)
    }
  }

  const renderMonth = useMemo(() => {
    return MONTHS.map(({ label, value }, index) => {
      return <MenuItem value={value} key={'month' + index}>{label}</MenuItem>
    })
  }, [])

  const renderYear = useMemo(() => {
    const currentYear = (new Date()).getFullYear()
    const render = []
    for (let i = 2024; i < (currentYear + 1); i++) {
      render.push(<MenuItem value={i} key={'ano' + i}>{i}</MenuItem>)
    }
    return render
  }, [])

  const renderReport = useMemo(() => {
    if (error) {
      console.log(error)
      return
    }
    if (isLoading || !data) return
    const usersKeys = Object.keys(data).sort()
    const response = []

    for (const userKey of usersKeys) {
      const userData = {
        user: userKey,
        annualLeave: formatTimeToStr(data[userKey].annualLeave)
      }

      delete data[userKey].annualLeave
      let periodWorkTime = 0
      for (const date of Object.keys(data[userKey])) {
        periodWorkTime += data[userKey][date].dailyWorkingTime
      }

      userData.periodWorkTime = formatTimeToStr(periodWorkTime)

      response.push(
        <Paper key={userKey} sx={{ padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '8px', flexGrow: 1 }} elevation={2}>
          <Typography variant='h5' sx={{ marginBottom: '8px' }}>
            {userData.user}
          </Typography>
          <Typography>
            Horas trabalhadas no período: {userData.periodWorkTime}
          </Typography>
          <Typography>
            Banco de Horas: {userData.annualLeave}
          </Typography>
        </Paper>
      )
    }

    return response
  }, [data, error, isLoading])


  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '16px', justifyContent: 'space-between' }} >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '16px', minWidth: '25%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }} >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '16px', flexGrow: 1 }}>
            <Paper sx={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px' }} elevation={2}>
              <Typography variant='h5'>
                Parâmetros
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', marginBottom: '16px' }}>
                <FormControl fullWidth sx={{ minWidth: '120px' }}>
                  <InputLabel id="month-select-label">Mês</InputLabel>
                  <Select
                    labelId="month-select-label"
                    id="month-select"
                    value={month}
                    disabled={isLoading}
                    label="Mês"
                    onChange={(e) => handleChange(e, 'month')}
                  >
                    {renderMonth}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ minWidth: '120px' }}>
                  <InputLabel id="year-select-label">Ano</InputLabel>
                  <Select
                    labelId="year-select-label"
                    id="year-select"
                    disabled={isLoading}
                    value={year}
                    label="Ano"
                    onChange={(e) => handleChange(e, 'year')}
                  >
                    {renderYear}
                  </Select>
                </FormControl>
              </Box>
              <Button variant="contained" onClick={loadData} disabled={isLoading} >Gerar relatório</Button>
            </Paper>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '16px', flexGrow: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', gap: '16px', marginBottom: '16px' }}>
          {renderReport}
        </Box>
      </Box>
    </Box>
  )
}

export default ReportPage