import { useMemo } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import LogoutIcon from '@mui/icons-material/Logout'
import { Paper } from '@mui/material'
import { useTheme } from '@emotion/react'
import useAuthStore from '../../../store/auth'
import MenuIcon from '@mui/icons-material/Menu'
import { AUTHENTICATED_ROUTES } from '../../../utils/routes'
import { useLocation } from 'react-router-dom'

const ROUTE_NAMES = {}
AUTHENTICATED_ROUTES.forEach((route) => {
  ROUTE_NAMES[route.path] = route.label
})

const Navbar = (props) => {
  const { drawerHandler } = props
  const theme = useTheme()
  const location = useLocation()
  const user = useAuthStore(state => state.user)
  const clearUserData = useAuthStore(state => state.clearUserData)

  const logoutHandler = () => {
    clearUserData()
  }

  const auth = useMemo(() => {
    return !!user.id
  }, [user])

  const name = useMemo(() => {
    return user.name.split(' ')[0]
  }, [user])

  const pageName = useMemo(() => {
    const pathname = location.pathname
    return ROUTE_NAMES[pathname]
  }, [location])

  return (
    <Box sx={{ flex: 1, marginBottom: '16px' }}>
      <Paper elevation={2} sx={{ backgroundColor: theme.palette.mode === 'light' && 'primary.main', color: 'primary.contrastText' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={drawerHandler}
            edge="start"
            sx={{ mr: 2, display: { xs: 'block', md: 'none'}}}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {pageName}
          </Typography>
          {auth && (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
              <Typography variant="h6" component="div">
                {name}
              </Typography>
              <IconButton
                size="large"
                aria-label="logout button"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={logoutHandler}
              >
                <LogoutIcon />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </Paper>
    </Box>
  )
}

export default Navbar