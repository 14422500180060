export const AuthFlowMode = {
  LOGIN: 0,
  REFRESH_TOKEN: 1,
}

export const RecoverFlowMode = {
  REQUEST: 'request',
  CONFIRM: 'confirm',
}

export const FormMode = {
  SIGNIN: 1,
  NEW_PASSWORD_REQUIRED: 2,
  NOT_CONFIRMED: 3,
  RECOVER_PASSWORD: 4,
}

export const CognitoUserStatusText = {
  UNCONFIRMED: 'Não Confirmado',
  CONFIRMED: 'Confirmado',
  ARCHIVED: 'Arquivado',
  COMPROMISED: 'Comprometido',
  UNKNOWN: 'Desconhecido',
  RESET_REQUIRED: 'Redefinição necessária',
  FORCE_CHANGE_PASSWORD: 'Troca de senha forçada',
}

export const API_URL_MAP = {
  dev: 'http://localhost:3333/',
  production: 'https://timetracker.omega7systems.com/api/'
}

export const USER_CLASS_WORK_TIME = Object.freeze({
  temp: 6 * 60 * 60,
  worker: 8 * 60 * 60
})

export const ICON_PROPS = {
  fontSize: 'large',
  htmlColor: '#fff'
}

export const MONTHS = [
  { label: 'Janeiro', value: 1 },
  { label: 'Fevereiro', value: 2 },
  { label: 'Março', value: 3 },
  { label: 'Abril', value: 4 },
  { label: 'Maio', value: 5 },
  { label: 'Junho', value: 6 },
  { label: 'Julho', value: 7 },
  { label: 'Agosto', value: 8 },
  { label: 'Setembro', value: 9 },
  { label: 'Outubro', value: 10 },
  { label: 'Novembro', value: 11 },
  { label: 'Dezembro', value: 12 }
]