import { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import CustomCard from '../../components/base/card'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import useAuthStore from "../../store/auth"
import { usePost } from '../../utils/hooks'
import AppointmentCalendar from '../../components/appointmentCalendar'
import { ICON_PROPS } from '../../utils/consts'
import { formatTimeToStr } from '../../utils/helpers'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import Fab from '@mui/material/Fab'
import { setHours } from 'date-fns'

const Dashboard = () => {
  const user = useAuthStore(state => state.user)
  const { data, error, isLoading } = usePost('appointment/getByDate', {
    data: {
      discordUser: user.discordUser,
      date: setHours(new Date(), 3).toUTCString()
    }
  })

  // const [createAppointment, { data: createData, error: createError, isLoading: createLoading }] = useLazyPost('appointment/create/')

  const dailyWorkingTime = useMemo(() => {
    if (isLoading || error) return
    return formatTimeToStr(data.dailyWorkingTime)
  }, [data, isLoading, error])

  const annualLeave = useMemo(() => {
    return formatTimeToStr(user.annualLeave)
  }, [user])

  const onDateChangeHandler = () => {

  }

  // const fastCheckInHandler = useCallback(async () => {
  //   const params = {
  //     discordUser: user.discordUser,
  //     isClosing: false,
  //     hour: new Date(),
  //     isCorrection: false,
  //     corretionOf: null,
  //     description: 'batida rápida'
  //   }
  //   await createAppointment(params)
  // }, [user, createAppointment])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between', gap: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', gap: '16px' }}>
        <CustomCard
          label="Banco de horas"
          value={annualLeave}
          icon={<AccessTimeIcon {...ICON_PROPS} />}
          iconBackground="primary.main"
          loading={isLoading}
        />
        <CustomCard
          label="Horas do dia"
          value={dailyWorkingTime}
          icon={<AccessTimeIcon {...ICON_PROPS} />}
          iconBackground="success.main"
          loading={isLoading}
        />
        {/* <CustomCard
          label="Banco de horas"
          value='57h22m'
          icon={<AccessTimeIcon {...ICON_PROPS} />}
          iconBackground="warning.main"
          loading={isLoading}
        />
        <CustomCard
          label="Banco de horas"
          value='57h22m'
          icon={<AccessTimeIcon {...ICON_PROPS} />}
          iconBackground="warning.main"
          loading={isLoading}
        />
        <CustomCard
          label="Horas do dia"
          value='04h33m'
          icon={<AccessTimeIcon {...ICON_PROPS} />}
          iconBackground="error.main"
          loading={isLoading} 
        />*/}
      </Box >
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', gap: '16px' }}>
        <AppointmentCalendar onDateChangeHandler={onDateChangeHandler} />
      </Box>
      {/* <Box sx={{ position: 'fixed', right: 0, bottom: 0, margin: '32px 16px' }}>
        <Fab color="secondary" aria-label="fast check-in">
          <ScheduleSendIcon sx={{ marginLeft: '4px' }} onClick={fastCheckInHandler} />
        </Fab>
      </Box> */}
    </Box>
  )
}

export default Dashboard