import { create } from 'zustand'
const USER_INITIAL_DATA = {
  id: null,
  email: '',
  name: '',
  isActive: false,
  class: 'worker',
  discordUser: '',
  firstLogin: false,
  annualLeave: 0,
  isAdmin: false
}
const useAuthStore = create((set) => ({
  user: USER_INITIAL_DATA,
  saveUserData: (newUser) => set({ user: newUser }),
  clearUserData: () => set({ user: USER_INITIAL_DATA })
}))

export default useAuthStore