import { useCallback, useEffect, useMemo, useState } from 'react'
import Box from "@mui/material/Box"
import useAuthStore from "../../store/auth"
import { useLazyPost } from '../../utils/hooks'
import { format, setHours } from 'date-fns'
import CustomTable from '../../components/base/table'
import AppointmentCalendar from '../../components/appointmentCalendar'
import CustomCard from '../../components/base/card'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { ICON_PROPS } from '../../utils/consts'
import { formatTimeToStr } from '../../utils/helpers'
import AddIcon from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab'
import NewAppointmentModal from '../../components/newAppointmentModal'
import { GridActionsCellItem } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'

const COLUMNS = (editHandler) => [
  { field: 'date', headerName: 'Dia', width: 100, valueGetter: (val, row) => format(new Date(row.date), 'dd/MM/yyyy') },
  { field: 'hour', headerName: 'Hora', width: 80, valueGetter: (val, row) => format(new Date(row.date), 'HH:mm') },
  {
    field: 'type',
    headerName: 'Tipo',
    width: 90,
    sortable: false,
    valueGetter: (value, row) => row.isClosing ? 'Saída' : 'Entrada',
  },
  {
    field: 'description',
    headerName: 'Descrição',
    sortable: false,
    width: 230,
  },
  {
    field: 'isCorrected',
    headerName: 'Corrigido',
    type: 'boolean',
    sortable: false,
    width: 120,
  },
  {
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Editar"
        onClick={() => editHandler(params.row)}
      />
    ],
  },
]

const Appointment = () => {
  const user = useAuthStore(state => state.user)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedDate, setSelectedData] = useState(new Date())
  const [editValues, setEditValues] = useState({})
  const [loadAppointments, { data, isLoading, error }] = useLazyPost('appointment/getByDate')
  const [createAppointment, { error: createError }] = useLazyPost('appointment/create/')
  const [updateAppointment, { error: updateError }] = useLazyPost('appointment/update/')

  const loadData = useCallback(async (date = new Date()) => {
    const params = {
      discordUser: user.discordUser,
      date: setHours(date, 3)
    }
    loadAppointments(params)
  }, [loadAppointments, user])

  const onDateChangeHandler = (date) => {
    setSelectedData(date)
    const params = {
      discordUser: user.discordUser,
      date: setHours(date, 3)
    }
    loadAppointments(params)
  }

  const createHandler = () => {
    setIsEditMode(false)
    setIsModalOpen(true)
  }                    

  const editHandler = (row) => {
    const data = {
      id: row.id,
      type: row.isClosing,
      description: row.description,
      hour: new Date(row.date)
    }
    setEditValues(data)
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const saveHandler = useCallback(async (formData) => {
    const dateToSave = new Date(selectedDate)
    dateToSave.setHours(formData.hour.getHours())
    dateToSave.setMinutes(formData.hour.getMinutes())
    if (isEditMode) {
      const data = {
        appointmentId: editValues.id,
        newDate: dateToSave,
        newDescription: formData.description
      }
      await updateAppointment(data)
      if (updateError) {
        console.log(updateError)
        return 
      }
    } else {
      const data = {
        discordUser: user.discordUser,
        isClosing: formData.type,
        hour: dateToSave,
        isCorrection: false,
        corretionOf: null,
        description: formData.description
      }
      await createAppointment(data)
      if (createError) {
        console.log(createError)
        return
      }
    }
    setIsModalOpen(false)
    loadData()
  }, [createAppointment, selectedDate, user, createError, loadData, updateAppointment, updateError, editValues, isEditMode])

  const dailyWorkingTime = useMemo(() => {
    if (error || isLoading) return 0
    if (!data) return 0
    return formatTimeToStr(data.dailyWorkingTime)
  }, [data, isLoading, error])

  const rows = useMemo(() => {
    if (error || isLoading) return []
    if (!data) return []
    return data.allDayAppointments
  }, [data, isLoading, error])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', gap: '16px' }
    }>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', minWidth: '25%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', minWidth: '25%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: '16px', minWidth: '25%' }}>
            <CustomCard
              label="Horas do dia"
              value={dailyWorkingTime}
              icon={<AccessTimeIcon {...ICON_PROPS} />}
              iconBackground="success.main"
              loading={isLoading}
            />
            <AppointmentCalendar onDateChangeHandler={onDateChangeHandler} />
          </div>
        </div>
      </div>

      <div style={{ flexGrow: 1 }}>
        <CustomTable
          rows={rows}
          loading={isLoading}
          columns={COLUMNS(editHandler)}
          label='Apontamentos'
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
        />
      </div>

      <NewAppointmentModal
        open={isModalOpen}
        handleClose={closeModal}
        isEditMode={isEditMode}
        saveHandler={saveHandler}
        values={editValues}
      />
      <Box sx={{ position: 'fixed', right: 0, bottom: 0, margin: '32px 16px' }}>
        <Fab color="secondary" aria-label="fast check-in">
          <AddIcon onClick={createHandler} />
        </Fab>
      </Box>
    </Box>
  )
}

export default Appointment