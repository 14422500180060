import Select from '@mui/material/Select'
import { Controller } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

const ControlledSelect = ({ id, name, label, control, disabled = false, options, rules = {}, ...props }) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState }) => {
      return (
        <FormControl fullWidth>
          <InputLabel id={`label-${id}`}>{label}</InputLabel>
          <Select
            {...field}
            {...props}
            labelId={`label-${id}`}
            id={id}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            disabled={disabled}
            label={label}
          >
            {options}
          </Select>
        </FormControl>
      )
    }}
  />
)

export default ControlledSelect
